import React, {useCallback, useState} from "react";
import b from './Modal.module.scss';
import ReactModal from 'react-modal';
import IconClose from "./assets/IconClose";
import {useDispatch, useSelector} from "react-redux";
import {closeFormModal, formHasBeenOpened, getModalFormStatus, getVote, setUserVerified} from "../../../store/survey";
import {useMotionValueEvent, useScroll} from "framer-motion";
import {Headline} from "../../../components/headline/Headline";
import {Highlight} from "../../../components/highlight/Highlight";
import {ModalForm} from "./ModalForm";
import {isEmpty} from "ramda";

export const Modal = () => {
    const isModalOpen = useSelector(getModalFormStatus);
    const vote = useSelector(getVote);
    const [scrollTop, setScrollTop] = useState(0);
    const {scrollY} = useScroll()
    const dispatch = useDispatch();
    useMotionValueEvent(scrollY, "change", (latest) => {
        if (isModalOpen === false) {
            setScrollTop(latest);
        }
    })
    const handleOnAfterOpen = useCallback(() => {
        document.body.style.top = `-${scrollTop}px`;
    }, [scrollTop]);
    const handleOnAfterClose = useCallback(() => {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY) * -1);
    }, []);

    const handleRequestClose = () => {
        const {name, email, emailVerified} = vote;
        if (!isEmpty(name) && !isEmpty(email) && emailVerified === true) {
            dispatch(setUserVerified(true));
        }
        dispatch(formHasBeenOpened());
        dispatch(closeFormModal());
    };
    return (
        <ReactModal
            isOpen={isModalOpen}
            onAfterOpen={handleOnAfterOpen}
            onAfterClose={handleOnAfterClose}
            onRequestClose={handleRequestClose}
            closeTimeoutMS={420}
            contentLabel="Форма голосования"
            portalClassName={b.portal}
            overlayClassName={{
                base: b.overlay,
                afterOpen: b.overlayAfterOpen,
                beforeClose: b.overlayBeforeClose
            }}
            id={"some-id"}
            className={b.content}
            bodyOpenClassName={b.bodyOpen}
            htmlOpenClassName={b.htmlOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            role={"dialog"}
            preventScroll={true}
            parentSelector={() => document.querySelector('#modal-root')}
        >
            <button type="button" onClick={handleRequestClose} className={b.closeButton}>
                <IconClose/>
            </button>
            <div className={b.container}>
                <Headline dark={true} className={b.headline}>
                    Пройдите <Highlight>верификацию,</Highlight> <br/> чтобы проголосовать
                </Headline>
                <div className={b.subHeadline}>
                    Мы отправим проверочный код на указанный электронный адрес для подтверждения
                </div>
                <div className={b.formContainer}>
                    <ModalForm/>
                </div>
            </div>
        </ReactModal>
    );
}
