import React from 'react';
import b from './Footer.module.scss';
import {Highlight} from "../../components/highlight/Highlight";
import {Headline} from "../../components/headline/Headline";
import logo from './assets/logo-footer-winline.png';
import logo2x from './assets/logo-footer-winline@2x.png';
import {SocialLinks} from "../../features/social-links/SocialLinkList";
import {SocialLink} from "../../features/social-links/SocialLinkItem";

export const Footer = () => {
    return (
        <footer className={b.footer}>
            <div className="medium-margin">
                <div className="row">
                    <div className="column large-11">
                        <Headline className={`${b.footerHeadline}`} dark={true}>
                            <Highlight>ПОДЕЛИТЕСЬ ГОЛОСОВАНИЕМ</Highlight> <br/> В СВОИХ СОЦИАЛЬНЫХ СЕТЯХ
                        </Headline>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-7 large-5">
                        <div className={b.footerNote}>
                            <p>Подключайте к голосованию своих друзей и вместе решайте, на что потратить средства
                                фонда</p>
                        </div>
                    </div>
                </div>
                <div className="row small-m-t-25 medium-m-t-30 m-t-50">
                    <div className="column small-12 medium-4 large-3 align-center">
                        <div className={b.footerLogo}>
                            <a href="https://clck.ru/36DgUb"
                               target="_blank"
                               rel="noreferrer">
                                <img
                                    src={logo}
                                    srcSet={`${logo2x} 2x`}
                                    width="200"
                                    height="48"
                                    draggable="false"
                                    alt="Winline Logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="column small-12 medium-offset-1 medium-7 large-offset-3 large-6 small-m-t-40">
                        <SocialLinks className="align-right" theme="light">
                            <SocialLink type="telegram"
                                        link="https://t.me/share/url?url=https%3A%2F%2Fvp.winline-vote.ru&text=%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%D0%B2%20%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8%21%20%D0%9D%D0%B0%20%D0%BA%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%20%D0%BF%D0%BE%D1%82%D1%80%D0%B0%D1%82%D0%B8%D1%82%D1%8C%20%D1%84%D0%BE%D0%BD%D0%B4%20VIRTUS.PRO%20%D0%B8%20Winline%3F"/>
                            <SocialLink type="vk" link="https://vk.com/share.php?url=https%3A%2F%2Fvp.winline-vote.ru&title=%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%D0%B2%20%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8%21%20%D0%9D%D0%B0%20%D0%BA%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%20%D0%BF%D0%BE%D1%82%D1%80%D0%B0%D1%82%D0%B8%D1%82%D1%8C%20%D1%84%D0%BE%D0%BD%D0%B4%20VIRTUS.PRO%20%D0%B8%20Winline%3F"/>
                            <SocialLink type="ok" link="https://connect.ok.ru/offer?url=https%3A%2F%2Fvp.winline-vote.ru&title=%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%D0%B2%20%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8%21%20%D0%9D%D0%B0%20%D0%BA%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%20%D0%BF%D0%BE%D1%82%D1%80%D0%B0%D1%82%D0%B8%D1%82%D1%8C%20%D1%84%D0%BE%D0%BD%D0%B4%20VIRTUS.PRO%20%D0%B8%20Winline%3F"/>
                            <SocialLink type="wa" link="%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%D0%B2%20%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8%21%20%D0%9D%D0%B0%20%D0%BA%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%20%D0%BF%D0%BE%D1%82%D1%80%D0%B0%D1%82%D0%B8%D1%82%D1%8C%20%D1%84%D0%BE%D0%BD%D0%B4%20%D0%A4%D0%9A%20%C2%AB%D0%A1%D0%BF%D0%B0%D1%80%D1%82%D0%B0%D0%BA%C2%BB%20%D0%B8%20Winline%3F%20http%3A%2F%2Fvp.winline-vote.ru"/>
                            <SocialLink type="link" link="https://vp.winline-vote.ru"/>
                        </SocialLinks>
                    </div>
                </div>
            </div>
            <div className={b.footerLinksContainer}>
                <div className="medium-margin">
                    <div className="row">
                        <div className="column small-12 medium-5 large-6">
                            <div className={b.footerLinks}>
                                <div className={b.footerLink}>
                                    Поддержка – <a href="mailto:help@winline-vote.ru">help@winline-vote.ru</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
