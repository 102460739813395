import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <rect width={16} height={16} fill="#FF6A13" rx={8} />
        <path
            fill="#fff"
            fillRule="evenodd"
            stroke="#fff"
            strokeWidth={0.8}
            d="M11 6.84 7.678 10.5 5 8.286l.485-.4 2.12 1.752L10.451 6.5l.548.34Z"
            clipRule="evenodd"
        />
    </svg>
)
const IconResultSmall = memo(SvgComponent)
export default IconResultSmall
