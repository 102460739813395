import React from "react";
import b from './Header.module.scss'
import { ReactComponent as LogoVP } from './assets/logo/logo-vp.svg';
import { ReactComponent as LogoWinline } from './assets/logo/logo-wnln.svg';
import {Headline} from "../../components/headline/Headline";
import {Button} from "../../components/button/Button";
import mainComposition from "./assets/composition/main-composition.png";
import mainComposition2x from "./assets/composition/main-composition@2x.png";
import {Ticker} from "../../features/ticker/Ticker";

export const Header = () => {
    const handleClickScroll = () => {
        const element = document.getElementById('survey');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className={b.container}>
            <div className={b.logosContainer}>
                <div className="medium-margin">
                    <div className="row">
                        <div className="column large-12">
                            <div className={b.logos}>
                                <a className={b.winlineLogo}
                                   target="_blank"
                                   href="https://clck.ru/36ERb3" rel="noreferrer">
                                    <LogoWinline />
                                </a>
                                <span className={b.splitter}></span>
                                <a className={b.partnerLogo} target="_blank" href="https://virtus.pro" rel="noreferrer">
                                    <LogoVP />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medium-margin">
                <div className="row">
                    <div className="column small-12 small-order-2 medium-6 medium-order-1 large-6 large-order-1 position-relative">
                        <div className={b.mainComposition}>
                            <img
                              className="hide-for-medium"
                              src={mainComposition} srcSet={`${mainComposition2x} 2x`}
                              draggable="false"
                              alt=""
                            />
                        </div>
                    </div>
                    <div className="column small-order-1 medium-6 medium-order-2 large-6 large-order-2">
                        <div className={b.headlineContainer}>
                            <Headline className={b.headline}>
                                Вы решаете <br/> на что потратить
                            </Headline>
                            <Headline className={b.headlineAccent}>
                                фонд любимого <br className="hide-for-medium"/> клуба
                            </Headline>
                        </div>
                    </div>
                    <div className="column small-order-3 small-12 medium-6 medium-offset-6 medium-order-3 large-6 large-offset-6 large-order-3">
                        <div className={b.buttonContainer}>
                            <div className={b.buttonLabel}>
                                Голосуйте ответственно! <br/> Возможно, именно ваш выбор станет решающим
                            </div>
                            <div>
                                <Button onClick={handleClickScroll} effect="shine" styleButton="head-button" className={b.button} disabled={false} type="button" expanded={false}>
                                    Принять участие в опросе
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={b.tickerContainer}>
                <Ticker />
            </div>
        </div>
    );
}
