import * as React from "react"
import {memo} from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <path
            d="M13.072 19C4.872 19 .195 13.37 0 4h4.107c.135 6.877 3.163 9.79 5.562 10.39V4h3.868v5.93c2.368-.254 4.856-2.957 5.696-5.93H23.1c-.644 3.664-3.343 6.366-5.261 7.477C19.758 12.378 22.83 14.736 24 19h-4.258c-.914-2.853-3.192-5.06-6.205-5.36V19h-.465Z"
        />
    </svg>
)
const IconVk = memo(SvgComponent)
export default IconVk
