import * as React from "react"
import {memo} from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <path
            d="M1.471 10.71s10.615-4.39 14.296-5.936c1.412-.619 6.197-2.597 6.197-2.597s2.21-.866 2.025 1.236c-.061.866-.552 3.896-1.043 7.173-.736 4.638-1.534 9.709-1.534 9.709s-.123 1.422-1.166 1.67c-1.043.247-2.76-.866-3.067-1.114-.246-.185-4.602-2.968-6.197-4.328-.43-.371-.92-1.113.06-1.979 2.21-2.04 4.848-4.576 6.443-6.184.737-.742 1.473-2.473-1.595-.37-4.356 3.03-8.651 5.874-8.651 5.874s-.982.618-2.823.061a126.724 126.724 0 0 1-3.988-1.298s-1.472-.928 1.043-1.917Z"
        />
    </svg>
)
const IconTelegram = memo(SvgComponent)
export default IconTelegram
