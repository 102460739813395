import React, {useEffect} from "react";
import b from './Quesiton.module.scss'
import {QuestionList} from "./QuestionList";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    getIsFetchingResults,
    getIsFetchingVariant,
    getIsFetchingVote,
    getIsUserVerified,
    getIsVoted,
    getResults,
    getSelectedVariant,
    getVariant,
    openFormModal,
    openSuccessModal,
    requestVote
} from "../../../store/survey";
import loading from "./assets/loading.svg";
import {getIsSurveyFinished} from "../../../store/config";

export const QuestionForm = () => {
    const isFinishedSurvey = useSelector(getIsSurveyFinished);
    const isFetchingResults = useSelector(getIsFetchingResults);
    const isFetchingVariant = useSelector(getIsFetchingVariant);
    const selectedVariant = useSelector(getSelectedVariant);
    const isUserVerified = useSelector(getIsUserVerified);
    const isFetchingVote = useSelector(getIsFetchingVote);
    const isVoted = useSelector(getIsVoted);
    const variant = useSelector(getVariant);
    const results = useSelector(getResults);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isVoted) {
            dispatch(openSuccessModal());
        }
        // eslint-disable-next-line
    }, [isVoted]);
    const onClickHandler = () => {
        if (isUserVerified) {
            dispatch(requestVote());
        } else {
            dispatch(openFormModal());
        }
    };
    let buttonLabel = 'ГОЛОСОВАТЬ';
    if (variant) {
        buttonLabel = 'ВАШ ГОЛОС ПРИНЯТ';
    } else if (isFinishedSurvey === true) {
        buttonLabel = 'ГОЛОСОВАНИЕ ЗАВЕРШЕНО';
    }
    return (
        <div className={b.container}>
            {(isFetchingResults || isFetchingVariant) ? (
                <div className={b.loadingContainer}>
                    <img
                        className={b.loading}
                        src={loading} alt="ЗАГРУЗКА"
                    />
                </div>
            ) : (
                <QuestionList list={results} selected={selectedVariant} variant={variant}/>
            )}
            {isFetchingResults === false && isFetchingVariant === false && (
                <Button type="button"
                        onClick={onClickHandler}
                        disabled={!selectedVariant || variant || isFetchingVote || isFinishedSurvey}
                        expanded={true}>
                    {isFetchingVote ? (
                        <img
                            style={{width: 40}}
                            src={loading} alt=""
                        />
                    )
                        : buttonLabel
                    }
                </Button>
            )}
        </div>
    );
}
