import React from "react";
import {motion} from "framer-motion";
import b from "./Goals.module.scss"
import {Headline} from "../../components/headline/Headline";
import n01 from './assets/numbers/01.svg'
import n02 from './assets/numbers/02.svg'
import n03 from './assets/numbers/03.svg'
import cardImage1Medium from "./assets/cards/medium/card-image-1.png";
import cardImage1Medium2x from "./assets/cards/medium/card-image-1@2x.png";
import cardImage1Large from "./assets/cards/large/card-image-1.png";
import cardImage1Large2x from "./assets/cards/large/card-image-1@2x.png";
import cardImage2Medium from "./assets/cards/medium/card-image-2.png";
import cardImage2Medium2x from "./assets/cards/medium/card-image-2@2x.png";
import cardImage2Large from "./assets/cards/large/card-image-2.png";
import cardImage2Large2x from "./assets/cards/large/card-image-2@2x.png";
import cardImage3Medium from "./assets/cards/medium/card-image-3.png";
import cardImage3Medium2x from "./assets/cards/medium/card-image-3@2x.png";
import cardImage3Large from "./assets/cards/large/card-image-3.png";
import cardImage3Large2x from "./assets/cards/large/card-image-3@2x.png";

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const cardVariants = {
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: 1
        }
    },
    hidden: {
        scale: 0.8,
        opacity: 0
    }
};

const contentVariants = {
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delay: 0.1
        }
    },
    hidden: {opacity: 0}
};

const imageVariants = {
    visible: {
        opacity: 1,
        transition: {
            duration: 2,
            delay: 0.4
        }
    },
    hidden: {opacity: 0}
};

const Card = (props) => {
    const {content, image, reverse = false} = props;
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{once: true}}
        variants={cardVariants}
        className={cx(props.className, {
            cardContainer: true,
            reverse: reverse
        })}
      >
          <div className={b.card}>
              <motion.div
                variants={contentVariants}
                className={cx({
                    cardContent: true,
                })}>
                  {content}
              </motion.div>
              <motion.div
                variants={imageVariants}
                className={cx({
                    cardImage: true,
                })}>
                  {image}
              </motion.div>
          </div>
      </motion.div>
    )
}

export const Goals = () => {
    return (
      <div className={b.container}>
          <div className="medium-margin">
              <div className={`${b.header} row`}>
                  <div className="column small-12 medium-8 large-7">
                      <Headline className={b.headline}>
                          Обзор призового фонда клуба
                      </Headline>
                  </div>
                  <div className="column small-12 medium-7 medium-offset-5 large-5 large-offset-0 align-self-bottom">
                      <div className={b.headerText}>
                          Тебе предстоит выбрать из&nbsp;нескольких опций, как&nbsp;именно потратить собранные средства
                          и&nbsp;поддержать организацию. Ниже представлены варианты и&nbsp;их&nbsp;краткое описание.
                      </div>
                  </div>
                  <div className={b.coinsContainer}>
                      <span className={`${b.coin} ${b.coin1}`}></span>
                      <span className={`${b.coin} ${b.coin2}`}></span>
                      <span className={`${b.coin} ${b.coin3}`}></span>
                  </div>
              </div>
              <div className={`${b.cards} row`}>
                  <div className="column small-12 medium-12 large-12">
                      <Card className={b.cardFirst}
                            content={
                                <>
                                    <div className={`${b.cardNumber} text-hide`}>
                                        01 <img src={n01} title="01" alt="01" draggable="false"/>
                                    </div>
                                    <Headline className={b.cardTitle}>
                                        VIRTUS.PRO PARTY
                                    </Headline>
                                    <div className={b.cardText}>
                                        Организация грандиозной вечеринки для болельщиков и киберспортсменов! Праздник с
                                        интерактивами, весельем и музыкой длительностью в несколько часов. На тусовку
                                        будут приглашены топовые селебрити, популярные ведущие и, конечно, игроки
                                        составов Virtus.pro! Выбирая VP любимым клубом в приложении Winline, ты
                                        получаешь возможность попасть на вечеринку.
                                    </div>
                                </>
                            }
                            image={
                                <>
                                    <img
                                      className="hide-for-large"
                                      src={cardImage1Medium} srcSet={`${cardImage1Medium2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                    <img
                                      className="show-for-large"
                                      src={cardImage1Large} srcSet={`${cardImage1Large2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                </>
                            }>
                      </Card>
                  </div>
                  <div className="column small-12 medium-12 large-12">
                      <Card reverse={true} className={b.cardSecond}
                            content={
                                <>
                                    <div className={`${b.cardNumber} text-hide`}>
                                        02 <img src={n02} title="02" alt="02" draggable="false"/>
                                    </div>
                                    <Headline className={b.cardTitle}>
                                        Создание комикса
                                    </Headline>
                                    <div className={b.cardText}>
                                        Производство серии настоящих комиксов, супергероями в&nbsp;которых станут
                                        не&nbsp;только киберспортсмены, но&nbsp;и&nbsp;все фанаты VP. Драйвовая
                                        атмосфера, трушный дух&nbsp;графических романов, закрученный сюжет,
                                        первоклассный стиль&nbsp;— этот комикс рискует стать легендарным!
                                    </div>
                                </>
                            }
                            image={
                                <>
                                    <img
                                      className="hide-for-large"
                                      src={cardImage2Medium} srcSet={`${cardImage2Medium2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                    <img
                                      className="show-for-large"
                                      src={cardImage2Large} srcSet={`${cardImage2Large2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                </>
                            }>
                      </Card>
                  </div>
                  <div className="column small-12 medium-12 large-12">
                      <Card className={b.cardThird}
                            content={
                                <>
                                    <div className={`${b.cardNumber} text-hide`}>
                                        03 <img src={n03} title="03" alt="03" draggable="false"/>
                                    </div>
                                    <Headline className={b.cardTitle}>
                                        Создание уникальной капсулы мерча
                                    </Headline>
                                    <div className={b.cardText}>
                                        Выпуск собственной капсульной коллекции&nbsp;— мечта для&nbsp;многих почитателей
                                        VP.
                                        Зачем носить что‑то обычное, если&nbsp;можно одеться в&nbsp;мерч, выпущенный
                                        в&nbsp;коллаборации с&nbsp;современным брендом и&nbsp;выглядеть смело, ярко,
                                        трендово.
                                        Новая капсула&nbsp;— знак причастности к&nbsp;миру киберспорта!
                                    </div>
                                </>
                            }
                            image={
                                <>
                                    <img
                                      className="hide-for-large"
                                      src={cardImage3Medium} srcSet={`${cardImage3Medium2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                    <img
                                      className="show-for-large"
                                      src={cardImage3Large} srcSet={`${cardImage3Large2x} 2x`}
                                      draggable="false"
                                      alt=""
                                    />
                                </>
                            }>
                      </Card>
                  </div>
              </div>
          </div>
      </div>
    );
};
