import React from "react";
import b from './Button.module.scss'

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const BUTTON_TYPE = {
    a: 'a',
    button: 'button',
};

const ButtonAs = (props) => {
    return React.createElement(
        BUTTON_TYPE[props.as],
        {
            className: [
                typeof props.className !== 'undefined' && props.className
            ].join(' '),
            ...props,
        },
        props.children,
    );
}

export const Button = (props) => {
    const {
        as = "button",
        size = "default",
        styleButton = "button",
        expanded = false,
        disabled = false,
        effect = false,
        className,
        children,
        ...otherProps
    } = props;
    return (
        <div className={cx(className, {
            button: true,
            expanded: expanded,
            disabled: disabled,
            ['size-' + size]: true,
            ['style-' + styleButton]: true,
            ['effect-' + effect]: effect !== false,
        })}>
            <ButtonAs className={b.buttonEl} as={as} disabled={disabled} {...otherProps}>
                {children}
            </ButtonAs>
        </div>
    );
}
