import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <rect fill="#FF6A13" width={20} height={20} rx={10} />
        <path
            fill="#fff"
            fillRule="evenodd"
            stroke="#fff"
            strokeWidth={0.8}
            d="M14.5 7.51 10.071 13 6.5 9.68l.646-.601 2.826 2.627L13.77 7l.731.51Z"
            clipRule="evenodd"
        />
    </svg>
)
const IconResult = memo(SvgComponent)
export default IconResult
