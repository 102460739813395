import * as React from "react"
import {memo} from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <path
            fill="#fff"
            d="M18.774 11.422a1.461 1.461 0 0 0 0 2.062 1.461 1.461 0 0 0 2.063 0l1.038-1.031c2.834-2.834 2.834-7.493-.007-10.327-2.834-2.835-7.486-2.835-10.32 0l-1.031 1.031a1.46 1.46 0 1 0 2.063 2.07l1.03-1.039a4.385 4.385 0 0 1 6.195.007 4.385 4.385 0 0 1 0 6.195l-1.03 1.032ZM17.68 8.384a1.46 1.46 0 0 0 0-2.063 1.47 1.47 0 0 0-2.07 0L6.321 15.61a1.47 1.47 0 0 0 0 2.07 1.46 1.46 0 0 0 2.063 0l9.296-9.296Zm-4.196 12.453a1.461 1.461 0 0 0 0-2.063 1.461 1.461 0 0 0-2.062 0l-1.032 1.031a4.385 4.385 0 0 1-6.195 0 4.385 4.385 0 0 1-.007-6.194l1.039-1.032a1.46 1.46 0 1 0-2.07-2.062l-1.031 1.03c-2.835 2.835-2.835 7.487 0 10.321 2.834 2.841 7.493 2.841 10.327.007l1.031-1.038Z"
        />
    </svg>
)
const IconLink = memo(SvgComponent)
export default IconLink
