import React from "react";
import b from './Quesiton.module.scss'

import classNames from 'classnames/bind';
import IconResult from "./assets/IconResult";
import IconResultSmall from "./assets/IconResultSmall";
import {useDispatch} from "react-redux";
import {selectVariant} from "../../../store/survey";

let cx = classNames.bind(b);

export const QuestionItem = (props) => {
    const dispatch = useDispatch()
    const {id, finished, isActive = false, percent = "0", children} = props;
    const onClickHandler = () => {
        if (isActive === false && finished === false) {
            dispatch(selectVariant(id));
        }
    }
    return (
        <div onClick={onClickHandler} className={cx(props.className, {
            item: true,
            finished: finished,
            isActive: isActive
        })}>
            <div className={b.itemCell}>
                <div className={b.itemLabel}>
                    {children}
                </div>
                <div className={b.itemControls}>
                    {finished === true ?
                        (
                            <div className={b.result}>
                                {isActive && (
                                    <span className={b.selected}>
                                        <i className="show-for-medium">
                                            <IconResult/>
                                        </i>
                                        <i className="hide-for-medium">
                                            <IconResultSmall/>
                                        </i>
                                    </span>
                                )}
                                <span className={b.resultPercent}>{percent}%</span>
                            </div>
                        ) : (
                            <div className={b.option}>
                                <span className={b.optionCircle}></span>
                            </div>
                        )
                    }
                </div>
            </div>
            {finished === true && (
                <div style={{width: percent + '%'}} className={b.progress}></div>
            )}
        </div>
    );
}
