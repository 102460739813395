import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" {...props}>
        <path
            fillRule="evenodd"
            d="M11.817 9.999 20 1.817 18.181 0 10 8.181 1.82 0 .002 1.82l8.18 8.179L0 18.179l1.818 1.819L10 11.817 18.183 20l1.818-1.819L11.817 10Z"
            clipRule="evenodd"
        />
    </svg>
)
const IconClose = memo(SvgComponent)
export default IconClose
