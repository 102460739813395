import React from "react";
import b from './VotingForm.module.scss'
import {Headline} from "../../components/headline/Headline";
import {useSelector} from "react-redux";
import {getVariant} from "../../store/survey";
import {getIsSurveyFinished} from "../../store/config";

export const VotingHeadlines = () => {
    const isFinishedSurvey = useSelector(getIsSurveyFinished);
    const variant = useSelector(getVariant);
    return (
        <div>
            <Headline className={b.headline}>
                {variant || isFinishedSurvey === true
                    ? 'РЕЗУЛЬТАТЫ ГОЛОСОВАНИЯ'
                    : 'ГОЛОСОВАНИЕ WINLINE'
                }
            </Headline>
            {variant || isFinishedSurvey === true
                ? (
                    <div className={b.subHeadline}>
                        Финальный подсчет голосов состоится 8 декабря, результаты будут <br className="show-for-medium"/> объявлены в приложении и соцсетях Winline
                    </div>
                )
                : (
                    <div className={b.subHeadline}>
                        Выберите, во что вложить средства из фонда клуба
                    </div>
                )
            }
        </div>
    );
}
