import React from "react";
import b from './VotingForm.module.scss'
import {QuestionForm} from "./question/QuestionForm";
import {Modal} from "./modal/Modal";
import {ModalSuccess} from "./modal/ModalSuccess";
import {VotingHeadlines} from "./VotingHeadlines";

export const VotingForm = (props) => {
    return (
        <div className={b.container} {...props}>
            <VotingHeadlines />
            <QuestionForm/>
            <Modal/>
            <ModalSuccess/>
        </div>
    );
}
