import React from "react";
import b from './Ticker.module.scss'
import {motion, useScroll, useTransform} from "framer-motion";
import text from "./assets/text.png";
import text2x from "./assets/text@2x.png";

export const Ticker = () => {
    const {scrollYProgress} = useScroll();
    const x = useTransform(scrollYProgress, [0, 1], [-550, -1000]);
    return (
      <div className={b.container}>
          <div className={b.trackContainer}>
              <motion.div
                className={b.track}
                style={{x}}
              >
                  <img src={text} srcSet={`${text2x} 2x`} draggable="false" alt=""/>
                  <img src={text} srcSet={`${text2x} 2x`} draggable="false" alt=""/>
                  <img src={text} srcSet={`${text2x} 2x`} draggable="false" alt=""/>
              </motion.div>
          </div>
      </div>
    );
}
