import React from "react";
import b from './Modal.module.scss';
import * as Yup from 'yup';
import {Button} from "../../../components/button/Button";
import {Formik} from "formik";
import {Input} from "../../../components/input/Input";
import {InputEmail} from "../../../components/input/InputEmail";
import {useDispatch, useSelector} from "react-redux";
import {closeFormModal, getIsHasBeenOpened, getVote, setUserVerified} from "../../../store/survey";
import {isEmpty} from "ramda";

export const ModalForm = () => {
    const dispatch = useDispatch();
    const vote = useSelector(getVote);
    const isFormHasBeenOpened = useSelector(getIsHasBeenOpened);
    const initialValues = React.useRef({
        name: vote.name,
        email: vote.email,
        emailVerified: vote.emailVerified,
        otp: vote.otp,
        token: vote.token
    });
    return (
        <Formik
            isInitialValid={false}
            initialValues={initialValues.current}
            initialTouched={{
                name: isFormHasBeenOpened === true,
                email: isFormHasBeenOpened === true,
            }}
            validateOnMount={isFormHasBeenOpened === true}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Обязательное поле'),
                email: Yup.string()
                    .email('Неверный адрес электронной почты')
                    .required('Обязательное поле'),
                emailVerified: Yup.bool().required().oneOf([true]),
                token: Yup.string().required(),
            })}
            onSubmit={(values) => {
                if (!isEmpty(values.name) && !isEmpty(values.email) && values.emailVerified === true) {
                    dispatch(setUserVerified(true));
                }
                dispatch(closeFormModal());
            }}
        >
            {({
                  handleSubmit,
                  values,
                  isSubmitting,
                  isValid,
                  isValidating,
              }) => (
                <form onSubmit={handleSubmit}>
                    <div className={b.formRow}>
                        <div className={b.formColumn}>
                            <Input
                                name='name'
                                value={values.name}
                                placeholder='ФИО'
                            />
                        </div>
                    </div>
                    <div className={b.formRow}>
                        <div className={b.formColumn}>
                            <InputEmail
                                name='email'
                                type='email'
                                value={values.email}
                                placeholder='E-mail'
                            />
                        </div>
                    </div>
                    <div className={`${b.formRow} small-p-t-60`}>
                        <div className={b.formColumn}>
                            <div className={b.buttonContainer}>
                                <Button disabled={isSubmitting || isValidating || !isValid}
                                        type="submit"
                                        expanded={true}>
                                    К ГОЛОСОВАНИЮ
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}
