import React from "react";
import b from './FavoriteClub.module.scss'
import {Headline} from "../../components/headline/Headline";
import {useSelector} from "react-redux";
import {getFundsRaised} from "../../store/config";
import winlineLogo from "./assets/winline-logo.png";
import winlineLogo2x from "./assets/winline-logo@2x.png";
import {Button} from "../../components/button/Button";

const FundsRaised = () => {
    const fundsRaised = useSelector(getFundsRaised) || 0;
    const fundsRaisedFormatted = new Intl.NumberFormat("ru-RU",).format(
        fundsRaised,
    );
    return (
        fundsRaised === 0 ? '–' : fundsRaisedFormatted
    );
}

export const FavoriteClub = () => {
    return (
        <div className={b.container}>
            <div className="medium-margin">
                <div className="row">
                    <div className="column small-12 medium-12 large-6">
                        <div className={b.headlineGroup}>
                            <Headline dark={true} className={b.headline}>
                                ЧТО ТАКОЕ <br className="show-for-large"/> ЛЮБИМЫЙ КЛУБ
                            </Headline>
                            <div className={b.winline}>
                                <a href="https://clck.ru/36EQBC"
                                   target="_blank"
                                   rel="noreferrer">
                                    <img
                                        src={winlineLogo} srcSet={`${winlineLogo2x} 2x`}
                                        draggable="false"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className={`${b.headerText} show-for-large`}>
                            <p>
                                Больше года&nbsp;болельщики, выбравшие команду <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}>VIRTUS.PRO</a> любимым клубом в&nbsp;приложении Winline, собирали <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}>1% с&nbsp;каждой ставки</a>, а&nbsp;Winline удваивал эту&nbsp;сумму. Вместе нам&nbsp;удалось накопить более <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}><FundsRaised/>&nbsp;₽!</a> Время выбрать, на&nbsp;что&nbsp;их&nbsp;потратить!
                            </p>
                            <p>
                                Проголосуйте за&nbsp;понравившийся вариант поддержки VP, проявите свою активную позицию и насладитесь результатом!
                            </p>
                        </div>
                    </div>
                    <div className="column small-12 medium-12 large-6">
                        <div className={b.cards}>
                            <div className={`${b.card} ${b.cardFirst}`}>
                                <Headline className={b.cardTitle}>
                                    <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer">
                                        Повышенный коэффициент
                                    </a>
                                </Headline>
                                <div className={b.cardText}>
                                    Получай специальные условия ставок на&nbsp;все игры любимой команды
                                </div>
                            </div>
                            <div className={`${b.card} ${b.cardSecond}`}>
                                <Headline className={b.cardTitle}>
                                    <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer">
                                        1% на развитие любимого клуба
                                    </a>
                                </Headline>
                                <div className={b.cardText}>
                                    Winline добавит еще 1% дополнительно к&nbsp;проценту от ставки болельщика
                                </div>
                            </div>
                            <div className={`${b.card} ${b.cardThird}`}>
                                <Headline className={b.cardTitle}>
                                    <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer">
                                        Бонусные баллы <br className="show-for-medium-only"/> за ставки
                                    </a>
                                </Headline>
                                <div className={b.cardText}>
                                    Получай бонусные баллы и обменивай их&nbsp;на подарки в приложении Winline
                                </div>
                            </div>
                        </div>
                        <div className={b.greenButtonContainer}>
                            <Button href="https://clck.ru/36EQX7"
                                    target="_blank"
                                    rel="noreferrer"
                                    styleButton="green-button"
                                    className={b.greenButton} as="a"
                                    expanded={false}>
                                ВЫБРАТЬ VIRTUS.PRO
                            </Button>
                        </div>
                    </div>
                    <div className="hide-for-large column small-12 medium-12">
                        <div className={b.headerText}>
                            <p>
                                Больше года&nbsp;болельщики, выбравшие команду <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}>VIRTUS.PRO</a> любимым клубом в&nbsp;приложении Winline, собирали <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}>1% с&nbsp;каждой ставки</a>, а&nbsp;Winline удваивал эту&nbsp;сумму. Вместе нам&nbsp;удалось накопить более <a target="_blank" href="https://clck.ru/36EQHW" rel="noreferrer" className={b.highlight}><FundsRaised/>&nbsp;₽!</a> Время выбрать, на&nbsp;что&nbsp;их&nbsp;потратить!
                            </p>
                            <p>
                                Проголосуйте за&nbsp;понравившийся вариант поддержки VP, проявите свою активную позицию и насладитесь результатом!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
