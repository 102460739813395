import React from "react";
import b from "./SocialLinks.module.scss";

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

export const SocialLinks = (props) => {
    const {children, theme = 'light'} = props;
    return (
        <div className={cx(props.className, {
            container: true,
            [`theme-${theme}`]: true,
        })}>
            {children}
        </div>
    );
}
