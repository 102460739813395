import React, {useState} from "react";
import b from "./SocialLinks.module.scss";
import { Tooltip } from 'react-tooltip'

import classNames from 'classnames/bind';
import IconTelegram from "./icons/IconTelegram";
import IconVk from "./icons/IconVk";
import IconOk from "./icons/IconOk";
import IconWa from "./icons/IconWa";
import IconLink from "./icons/IconLink";

let cx = classNames.bind(b);

const Icon = ({type}) => {
    switch (type) {
        case 'telegram':
            return <IconTelegram/>;
        case 'vk':
            return <IconVk/>;
        case 'ok':
            return <IconOk/>;
        case 'wa':
            return <IconWa/>;
        case 'link':
            return <IconLink/>;
        default:
            return null;
    }
}

export const SocialLink = (props) => {
    const {type, link} = props;
    const [isCopied, setIsCopied] = useState(false);
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(link)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    if (type === 'link') {
        return (
            <div className={cx(props.className, {
                item: true,
                [`${type}`]: true,
            })}>
                <Tooltip className={b.tooltip} id="copy-clipboard-informer" content="Ссылка скопирована" isOpen={isCopied} delayHide={5000} variant='light'/>
                <span onClick={handleCopyClick} onPointerDown={handleCopyClick} data-tooltip-id="copy-clipboard-informer" className={cx(props.className, {
                    'text-hide': true,
                    'icon': true,
                })}>
                    <Icon type={type}/>
                </span>
            </div>
        );
    } else {
        return (
            <div className={cx(props.className, {
                item: true,
                [`${type}`]: true,
            })}>
                <a className={cx(props.className, {
                    'text-hide': true,
                    'icon': true,
                })} target="_blank" href={link} rel="noreferrer">
                    <Icon type={type}/>
                </a>
            </div>
        );
    }
}
