import React, {useEffect} from "react";
import b from './Input.module.scss'
import {isEmpty} from "ramda";
import {useField} from "formik";
import classNames from 'classnames/bind';
import {useDispatch} from "react-redux";
import {changeVoteFormField} from "../../store/survey";

let cx = classNames.bind(b);

export const Input = (props) => {
    const {
        type = 'text',
        placeholder = '',
        ...inputProps
    } = props;
    const [field, meta] = useField(props);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeVoteFormField({name: field.name, value: field.value || ''}));
    // eslint-disable-next-line
    }, [field.value])
    return (
        <div className={cx({
            container: true,
        })}>
            <div className={cx(props.className, {
                group: true,
                up: !isEmpty(field.value),
                errors: meta.touched && meta.error
            })}>
                <input
                    className={cx({
                        input: true
                    })}
                    type={type}
                    {...field}
                    {...inputProps}
                />
                {placeholder && (
                    <span className={cx({
                        placeholder: true
                    })}>
                        {placeholder}
                    </span>
                )}
            </div>
            {meta.touched && meta.error ? (
                <div className={b.error}>{meta.error}</div>
            ) : null}
        </div>
    );
}
