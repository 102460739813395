import React from "react";
import b from './Survey.module.scss'
import {Headline} from "../../components/headline/Headline";
import {VotingForm} from "../../features/voting-form/VotingForm";
import {useSelector} from "react-redux";
import {getFundsRaised} from "../../store/config";

const FundsRaised = () => {
    const fundsRaised = useSelector(getFundsRaised) || 0;
    const fundsRaisedFormatted = new Intl.NumberFormat("ru-RU",).format(
        fundsRaised,
    );
    return (
        <div className={b.collectedSum}>
            <div className={b.amount}>
                {fundsRaised === 0 ? '–' : fundsRaisedFormatted}
                <span className={b.currency}>рублей</span>
            </div>
        </div>
    );
}

export const Survey = () => {
    return (
        <div className={b.container}>
            <div className="medium-margin">
                <div className={`row ${b.fundsRaisedContainer}`}>
                    <div className="column small-12 medium-5 align-self-middle-medium align-self-middle-large">
                        <Headline className={b.headline}>
                            УЖЕ&nbsp;СОБРАНО
                            В&nbsp;ФОНД&nbsp;VIRTUS.PRO
                        </Headline>
                    </div>
                    <div
                        className="column medium-7 align-self-middle-small align-self-middle-medium align-self-middle-large">
                        <div className={b.fundsRaised}>
                            <FundsRaised/>
                            <div className={b.coinsContainer}>
                                <span className={`${b.coin} ${b.coin1}`}></span>
                                <span className={`${b.coin} ${b.coin2}`}></span>
                                <span className={`${b.coin} ${b.coin3}`}></span>
                                <span className={`${b.coin} ${b.coin4}`}></span>
                                <span className={`${b.coin} ${b.coin5}`}></span>
                                <span className={`${b.coin} ${b.coin6}`}></span>
                                <span className={`${b.coin} ${b.coin7}`}></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-t-25">
                    <div className="column">
                        <VotingForm id="survey"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
