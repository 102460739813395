import * as React from "react"
import {memo} from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <g fill="#fff" clipPath="url(#a)">
            <path d="M10.818 16.905c-1.86-.194-3.538-.653-4.974-1.776-.178-.14-.362-.274-.525-.43-.629-.604-.692-1.296-.195-2.008.426-.61 1.14-.773 1.883-.423.144.068.28.152.412.243 2.677 1.84 6.354 1.89 9.04.083a2.46 2.46 0 0 1 .881-.455c.641-.165 1.24.07 1.583.632.394.64.388 1.266-.096 1.763-.743.763-1.637 1.314-2.63 1.7-.938.363-1.966.547-2.984.668.153.168.226.25.322.346 1.382 1.389 2.77 2.771 4.147 4.164.47.475.568 1.063.31 1.615-.284.604-.916 1-1.536.958-.393-.027-.7-.223-.971-.497-1.043-1.05-2.106-2.08-3.128-3.149-.297-.31-.44-.252-.702.018a208.932 208.932 0 0 1-3.188 3.201c-.482.475-1.055.56-1.614.29-.593-.289-.971-.895-.942-1.504.02-.412.223-.726.505-1.009 1.368-1.365 2.732-2.734 4.096-4.102.09-.091.175-.188.306-.328Z" />
            <path d="M11.951 12.148c-3.319-.011-6.04-2.763-6.021-6.087C5.949 2.699 8.672-.01 12.024 0c3.359.01 6.055 2.757 6.038 6.153-.017 3.317-2.758 6.006-6.11 5.995Zm3.017-6.08A2.958 2.958 0 0 0 12 3.103a2.963 2.963 0 0 0-2.975 3.002 2.958 2.958 0 0 0 2.99 2.943 2.952 2.952 0 0 0 2.953-2.978Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)
const IconOk = memo(SvgComponent)
export default IconOk
