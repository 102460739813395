import React from "react";
import b from './Quesiton.module.scss'
import {QuestionItem} from "./QuestionItem";
import {isNotNil} from "ramda";
import {useSelector} from "react-redux";
import {getIsSurveyFinished} from "../../../store/config";

export const QuestionList = ({list, selected, variant}) => {
    const isFinishedSurvey = useSelector(getIsSurveyFinished);
    return (
        <div className={b.list}>
            {list.map(({id, variantTitle, result}) => (
                <QuestionItem
                    key={id}
                    id={id}
                    percent={result}
                    finished={isNotNil(variant) || isFinishedSurvey === true}
                    isActive={
                        isNotNil(variant) ? (
                            variant === id
                        ) : (
                            selected === id
                        )
                    }
                >
                    {variantTitle}
                </QuestionItem>
            ))}
        </div>
    );
}
